import React from 'react';
import '../../CSS/Email.css';



function EmailSending() {
  
  return (
    <form className='email-sending'
  action="https://formspree.io/f/xdoqwoyg"
  method="POST"
>
      <label>Name</label>
      <input type="text" name="user_name" required className='input-box'/>
      <label>Phone</label>
      <input type="tel" name="user_phone" required className='input-box'/>
      <label>Email</label>
      <input type="email" name="user_email" required className='input-box'/>
      <label>Degree</label>
      
      <select name='user_degree' className='input-box' required>
        <option></option>
        <option>B.E</option>
        <option>B.Tech</option>
        <option>B.sc</option>
        <option>B.com</option>
      </select>
      {/* <input type="text" name="user_degree" required className='input-box' placeholder='e.g B.E'/> */}
      <label>Specialization</label>
      <input type="text" name='user_specialization' required className='input-box' placeholder='e.g CSE'/>
      <label>Experience</label>
      <input type='number' name="user_Experience" required className='input-box'/>
      <label>Upload Resume</label>
      <input type="file" name="user_file" required className='input-box'/>

      <input type="submit" value="Send" />
    </form>
  );
}

export default EmailSending;

