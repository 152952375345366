import React from 'react';
import '../../CSS/ContactUs/contact.css';
import FbIcon from "../../Images/contact-icons/faceBook-icons/fb-icon-48.png";
import instaLogo from "../../Images/contact-icons/Instagram-icons/instagram-48.png";
import linkedInLogo from "../../Images/contact-icons/LinkedIn-icons/linked-in-48.png"
import { IoIosMail } from "react-icons/io";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { MdMyLocation } from "react-icons/md";
import { IoHome } from "react-icons/io5";


const Contactus = () => {

    return (
    <div className='contactpg'>
         
            <h1 className='contactus'>Get In Touch</h1>             
                   <div className='contact'>    
                    <div className='contact1'>      
                        <p><h2 className='comname'>
                        <div className='icon-home'>  <IoHome />  </div> MyInception Tech </h2><br/>No # 7/3 Shanthi Nivas,<br/>2nd Floor,<br/>Madley Road, <br/>Ashoka Nagar,<br/>T.Nagar,<br/> Chennai - 600017. <br/>   
                        <div className='googlemap'> <div ><MdMyLocation/></div>
                               
                                <a target='blank' href='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31096.89267400205!2d80.19405909999999!3d13.028565600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267660b0708a5%3A0x1f55b129324be639!2sMyInception%20Tech!5e0!3m2!1sen!2sin!4v1710250322701!5m2!1sen!2sin'> 
                                View on Map</a><br/>
                        </div></p>  
                    
                    </div>

                    <div className='contact2'>
                        <p> <h2 className='comname'> <div className='icon-mail'>  <IoIosMail /></div>Contact</h2><p>hr@myinceptiontech.com</p><p><MdOutlinePhoneInTalk /> +91 9884792981</p><p><MdOutlinePhoneInTalk />+91 9080556322</p></p>   
                    </div>  
                
                    <div  className='contact3'>                
                         <p>  <h2 className='icon-contact'><div className='icon-contact'> <FaClock /> </div> Timings</h2> 
                          Mon to Sat : 10.00 am - 6.30 pm <br/><p> <span style={{ color: 'red' }}>Sunday : Closed</span></p> </p>
                    </div>

            </div>
                  <div className='follows'>            
                  <h1 className='headingfollow'> Follow Us </h1>                            
                    <ul className='follows-list-icon'>
                        <li className='icons fb'><a href='https://www.facebook.com/share/RvnWbttJErwEXZQg/?mibextid=qi2Omg' target="_blank">  <img src={FbIcon} alt="Facebook" /> </a> </li>
                        <li className='icons linkedin'><a href= 'https://www.linkedin.com/company/myinception-tech/' tooltip="linkedin" target="_blank"><img src={linkedInLogo} alt="LinkedIn"/>  </a> </li>
                        <li className='icons instagram'><a href='https://instagram.com/myinception_tech?igshid=NGVhN2U2NjQ0Yg== ' tooltip="instagram" target="_blank"><img src={instaLogo} alt="Instagram"/></a></li>
                    </ul> 
                    <div className='copyRights'>
    Copyright © MyInceptionTech 2024. All rights reserved
</div>
        
                </div>
                   
     

    </div>   
      );
    }
    
export default Contactus;
