import React from "react";
import "reactjs-popup/dist/index.css";
import "../../CSS/HomePageContent.css";

import ModalWindow from "../../Reusuable_components/ModalWindow";
import homeImage from "../../Images/Home/24.png";

const HomePageContent = () => {
  const popupContent = [
    {
      heading:"Home",
      title: "Welcome to MyInception Tech - Your Gateway to Excellence",
      paragraph:
        "MyInception Tech stands as more than a consultancy we are your dedicated partners in shaping a fulfilling career or achieving business growth. Our mission is to arm professionals and businesses with the expertise, competence, and opportunities vital for excellence. We firmly believe that attaining excellence starts with the right training, support, and strategic services.",
    },
    {
      paragraph:
        " Our dedication to you is unwavering. We are determined to provide unparalleled services that bridge the gap between your aspirations and thriving careers or prosperous business ventures. Our team comprises seasoned professionals deeply passionate about Learning and Growth, ready to guide and assist you at every step.",
    },
    {
      paragraph:
        "As you explore our website, you'll discover a wealth of opportunities and resources. Our visually captivating pages are meticulously designed to furnish valuable insights into diverse industries, including IT, Software Development, Recruitment, Data Annotation, Call Center/BPO, and more. Our all-encompassing services address every facet of your professional or entrepreneurial journey.",
    },
    {
      paragraph:
        "Our gallery showcases images depicting our cutting-edge facilities, our team in action, and the inspiring success stories of our clients who have achieved remarkable results with our assistance.",
    },
    {
      paragraph:
        "We invite you to navigate through our website, where a treasure trove of information and guidance awaits, aiding you in making well-informed decisions about your career or business. MyInception Tech stands as your ultimate destination, empowering you, steering you in the right direction, and celebrating your accomplishments as you embark on this thrilling journey with us",
    },
    {
      title: "Where Excellence begins, and Dreams become reality.",
    },
  ];

  return (
    <div>
      <div>
        <div className="homePage">
          <div className="homePageImageContainer">
            <img src={homeImage} alt={"home"} className="HomePageImage"></img>
          </div>
          <div className="homePageContent">
            <h3 className="homePageHeading">Welcome to MyInception Tech - Your Gateway to Excellence</h3>
            <p className="homeParagraph">
              MyInception Tech stands as more than a consultancy we are your
              dedicated partners in shaping a fulfilling career or achieving
              business growth. Our mission is to arm professionals and
              businesses with the expertise, competence, and opportunities vital
              for excellence. We firmly believe that attaining excellence starts
              with the right training, support, and strategic services.
            </p>
            <ModalWindow
              triggerText="Read more..."
              customContent={popupContent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageContent;
