import "./App.css";
import CoreComponent from "./Pages/Courses/Core/CoreComponent";
import IT from "../src/Pages/Courses/IT/IT";
import AboutUsContent from "./Pages/AboutUs/AboutUsContent";
import NonITCoursess from "../src/Pages/Courses/Non-IT/Non-ITList";
import EmailSending from "./Pages/Contactus/Email-Sending";
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../src/Pages/Home/NavigationBar/Home'
import Services from "./Pages/Home/NavigationBar/Services";
import SignIn from "./Pages/AdminPage/SignIn";
import AdminCsvFileUpload from "./Pages/AdminPage/AdminCsvFileUpload";
import ITJobs from "./Pages/AdminPage/ITJobs";
import NonITJobs from "./Pages/AdminPage/NonITJobs";
import DashBoardJobPortal from "./Pages/AdminPage/DashBoardJobPortal";
import AdminMenuBar from "./Pages/AdminPage/AdminMenuBar";







function App() {
  return (
    <div className="App">


<BrowserRouter> 
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUsContent />}/>
      <Route path="/courses/it" element={<IT />} />
      <Route path="/courses/core" element={<CoreComponent />} />
      <Route path="/courses/non-it" element={< NonITCoursess />} />
      <Route path="/it-jobs" element={<ITJobs />} />
      <Route path="/non-it-jobs" element={<NonITJobs/>} />
      <Route path="/services" element={<Services />} />
      <Route path="/redirect/mail" element={<EmailSending /> } />

      <Route path='/admin-login' element={<SignIn />} />
      <Route path="/fileupload" element={<AdminCsvFileUpload />}/>

      <Route path="/dashboard" element={  <DashBoardJobPortal/>  }/>
      <Route path="/admin-menubar" element={  <AdminMenuBar />  }/> 

      
    </Routes>

    </BrowserRouter>
    </div>
  );
}

export default App;
