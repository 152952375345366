import React, { useState, } from 'react';
import {  Link } from 'react-router-dom';
import Logo from './Logo';
import { RxHamburgerMenu } from 'react-icons/rx';
import Courses from './Courses'

function NavigationBar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);


  return (
    
      <div className="NavigationBar">
         <Logo />       
         
        <button className="hamburger" onClick={() => setIsNavExpanded(!isNavExpanded)}>
          <RxHamburgerMenu />
        </button>
        <div className={isNavExpanded ? 'Nav-components expanded' : 'Nav-components'}>
          <ul>
            <li className='NavBar-list'>
              <Link to="/">Home</Link>

              
            </li>
            <li className='NavBar-list'>
              <Link to="/about" >About Us</Link>
            </li >
            <li id="List" className='NavBar-list'><Courses />
            </li>
            <li className='NavBar-list'>
              <Link to="/services" > Services </Link>
            </li>
          
          </ul>
        </div>
      </div>
  );
}
export default NavigationBar;
