
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../CSS/JobPortal/JobPortal.css";
import { CiLock, CiLocationOn } from "react-icons/ci";
import Button from "@mui/material/Button";
import ReactModal from "react-modal";
import LayOut from "../Home/NavigationBar/LayOut";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";

function ITJobs() {
  const [jobDetails, setJobDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get('http://localhost:5000/data');
        const itJobs = response.data.filter(job => job.industry === 'IT');
        setJobDetails(itJobs);
      } catch (error) {
        console.error('Error fetching IT job details:', error);
      }
    };

    fetchJobDetails();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedJob(null);
  };

  const handleJobClick = async (job) => {
    try {
      const response = await axios.get(`http://localhost:5000/job/${job._id}`);
      setSelectedJob(response.data);
      openModal();
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const calculateElapsedTime = (dateString) => {
    const postedDate = new Date(dateString);
    
    const currentDate = new Date();
    const elapsedMilliseconds = currentDate - postedDate;
    const elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
    return elapsedDays > 0 ? `${elapsedDays} days ago` : 'Posted today';
  };

  return (
    <LayOut>
      <div className="jobPortal">
        {jobDetails.map((information) => (
          <div key={information._id} className="searchJob">
            <div>
              <div className="designation">{information.designation}</div>
              <div className="company">{information.company}</div>
              <div className="work_details">
                <span>
                  <span className="lock-location-icon"><CiLock /></span> {information.experience}
                  <span className="separator1">|</span>
                  <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
                </span>
              </div>
            </div>
            <div className="vacancy">
              <span>
                <span>Vacancies: {information.vacancy}</span>
                <span className="separator">|</span>
                <span> ({calculateElapsedTime(information.postedOn)})</span>
              </span>
            </div>
            <div className="handleJobClick">
              <Button
                variant="contained"
                onClick={() => handleJobClick(information)}
              >
                Apply Here
              </Button>
            </div>
          </div>
        ))}

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Job Details Modal"
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              transform: "translate(0%, 7%)",
            },
          }}
        >
          {selectedJob && (
            <div style={{ maxWidth: "90vw", overflow: "auto" }}>
              <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
              <div>
                <h2>{selectedJob.designation}</h2>
                {selectedJob.industry && (
                  <p>
                    <h3>Industry:</h3>
                    {selectedJob.industry}
                  </p>
                )}
                {selectedJob.responsibilities && (
                  <>
                    <h3>Responsibilities</h3>
                    <ul>
                      {selectedJob.responsibilities.map((responsibility, idx) => (
                        <li key={idx}>{responsibility}</li>
                      ))}
                    </ul>
                  </>
                )}
                {selectedJob.requirements && (
                  <>
                    <h3>Requirements</h3>
                    <ul>
                      {selectedJob.requirements.map((requirement, idx) => (
                        <li key={idx}>{requirement}</li>
                      ))}
                    </ul>
                  </>
                )}
                <div className="apply-close-btn">
                  <Link to="/redirect/mail" target="_blank">
                    <div>
                      <Button variant="contained">Apply</Button>
                    </div>
                  </Link>
                  <div>
                    <Button variant="contained" onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    </LayOut>
  );
}

export default ITJobs;





//correct code....

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "../../CSS/JobPortal/JobPortal.css";
// import { CiLock, CiLocationOn } from "react-icons/ci";
// import Button from "@mui/material/Button";
// import ReactModal from "react-modal";
// import LayOut from "../Home/NavigationBar/LayOut";
// import { Link } from "react-router-dom";
// import { IoMdCloseCircle } from "react-icons/io";

// function ITJobs() {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedJob, setSelectedJob] = useState(null);

//   console.log('selectedJob' , selectedJob)

//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/data');
//         const itJobs = response.data.filter(job => job.industry === 'IT');
//         setJobDetails(itJobs);
//       } catch (error) {
//         console.error('Error fetching IT job details:', error);
//       }
//     };

//     fetchJobDetails();
//   }, []);

//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//     setSelectedJob(null);
//   };

//   const handleJobClick = (job) => {
//     setSelectedJob(job);
//     openModal();
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${day}-${month}-${year}`;
//   };

//   const calculateElapsedTime = (dateString) => {
//     const postedDate = new Date(dateString);
//     const currentDate = new Date();
//     const elapsedMilliseconds = currentDate - postedDate;
//     const elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
//     return elapsedDays > 0 ? `${elapsedDays} days ago` : 'Posted today';
//   };

//   // Posted On: {formatDate(information.postedOn)}

//   return (
//     <LayOut>
//       <div className="jobPortal">
//         {jobDetails.map((information) => (
//           <div key={information.id} className="searchJob">
//             <div>
//               <div className="designation">{information.designation}</div>
//               <div className="company">{information.company}</div>
//               <div className="work_details">
//                 <span>
//                   <span className="lock-location-icon"><CiLock /></span> {information.experience}
//                   <span className="separator1">|</span>
//                   <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
//                 </span>
//               </div>
//             </div>
//             <div className="vacancy">
//               <span>
//                 <span>Vacancies: {information.vacancy}</span>
//                 <span className="separator">|</span>
//                 <span> ({calculateElapsedTime(information.postedOn)})</span>
//               </span>
//             </div>
//             <div className="handleJobClick">
//               <Button
//                 variant="contained"
//                 onClick={() => handleJobClick(information)}
//               >
//                 Apply Here
//               </Button>
//             </div>
//           </div>
//         ))}

//         <ReactModal
//           isOpen={isModalOpen}
//           onRequestClose={closeModal}
//           contentLabel="Job Details Modal"
//           shouldCloseOnOverlayClick={false}
//           style={{
//             content: {
//               transform: "translate(0%, 7%)",
//             },
//           }}
//         >
//           {selectedJob && (
//             <div style={{ maxWidth: "90vw", overflow: "auto" }}>
//               <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
//               <div>
//                 <h2>{selectedJob.designation}</h2>
//                 {selectedJob.jobDescription && Array.isArray(selectedJob.jobDescription) && selectedJob.jobDescription.map((description, index) => (
//                   <div key={index}>
//                     {description.industry && (
//                       <p>
//                         <h3>Industry:</h3>
//                         {description.industry}
//                       </p>
//                     )}
//                     {description.responsibilities && (
//                       <>
//                         <h3>Responsibilities</h3>
//                         <ul>
//                           {description.responsibilities.map((responsibility, idx) => (
//                             <li key={idx}>{responsibility}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                     {description.requirements && (
//                       <>
//                         <h3>Requirements</h3>
//                         <ul>
//                           {description.requirements.map((requirement, idx) => (
//                             <li key={idx}>{requirement}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                     {description.recommendedSkills && (
//                       <>
//                         <h3>Recommended Skills</h3>
//                         <ul>
//                           {description.recommendedSkills.map((skill, idx) => (
//                             <li key={idx}>{skill}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                   </div>
//                 ))}

//                 <div className="apply-close-btn">
//                   <Link to="/redirect/mail" target="_blank">
//                     <div>
//                       <Button variant="contained">Apply</Button>
//                     </div>
//                   </Link>
//                   <div>
//                     <Button variant="contained" onClick={closeModal}>
//                       Close
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </ReactModal>
//       </div>
//     </LayOut>
//   );
// }

// export default ITJobs;

