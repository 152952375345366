
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../CSS/AdminPage/DashBoardJobPortal.css';

const DashBoardJobPortal = () => {
  const [jobDetails, setJobDetails] = useState([]);
  const [jobFilter, setJobFilter] = useState([]);
  const [editingJobId, setEditingJobId] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get('http://localhost:5000/data');
        setJobDetails(response.data);
        setJobFilter(response.data);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };

    fetchJobDetails();
  }, []);

  const filter = (e) => {
    setJobFilter(jobDetails.filter(job => job.name.includes(e.target.value)));
  };

  const totalRecords = jobFilter.length;

  const handleCancel = () => {
    setEditingJobId(null);
    setEditedFields({});
    setShowPopup(false);
  };

  //delete 

  const handleDelete = async (jobId) => {
    try {
      await axios.delete(`http://localhost:5000/data/${jobId}`);
      setJobDetails(prevJobDetails => prevJobDetails.filter(job => job._id !== jobId));
      console.log('Job deleted successfully');
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };



  //   // 5. save database
  // const handleSave = async (jobId) => {
  //   try {
  //     console.log("Saving job with ID:", jobId);
  //     console.log("Updated fields:", editedFields);
  //     await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
  //     setJobDetails(prevJobDetails => {
  //       return prevJobDetails.map(job => {
  //         if (job.Job_ID === jobId) {
  //           return { ...job, ...editedFields };
  //         }
  //         return job;
  //       });
  //     });
  //     setEditingJobId(null);
  //     console.log('Job updated successfully');
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //   }
  // };

  // const handleSave = async (jobId) => {
  //   try {
  //     console.log("Saving job with ID:", jobId);
  //     console.log("Updated fields:", editedFields);
  //     await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
  //     setJobDetails(prevJobDetails => {
  //       return prevJobDetails.map(job => {
  //         if (job._id === jobId) {
  //           return { ...job, ...editedFields };
  //         }
  //         return job;
  //       });
  //     });
  //     setEditingJobId(null);
  //     console.log('Job updated successfully');
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //   }
  // };

  // const handleSave = async (jobId) => {
  //   try {
  //     await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
  //     setJobDetails(prevJobDetails => {
  //       return prevJobDetails.map(job => {
  //         if (job.Job_ID === jobId) {
  //           return { ...job, ...editedFields };
  //         }
  //         return job;
  //       });
  //     });
  //     setEditingJobId(null);
  //     setShowPopup(false);
  //     console.log('Job updated successfully');
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //   }
  // };


  const handleSave = async (jobId) => {
    try {
      await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
      setJobDetails(prevJobDetails => {
        return prevJobDetails.map(job => {
          if (job._id === jobId) {
            return { ...job, ...editedFields };
          }
          return job;
        });
      });
      setJobFilter(prevJobFilter => {
        return prevJobFilter.map(job => {
          if (job._id === jobId) {
            return { ...job, ...editedFields };
          }
          return job;
        });
      });
      setEditingJobId(null);
      setEditedFields({});
      setShowPopup(false);
      console.log('Job updated successfully');
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  const handleChange = (event, fieldName) => {
    const newValue = event.target.value;
    setEditedFields(prevState => ({
      ...prevState,
      [fieldName]: newValue
    }));
  };


  const handleEdit = (jobId, jobData) => {
    const formattedData = {
      ...jobData,
      postedOn: formatDate(jobData.postedOn)
    };
    setEditingJobId(jobId);
    setEditedFields(formattedData);
    setShowPopup(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };


 
  return (
    <div className='dashboardpage'>
      <h1 className='dashheading'>Dashboard</h1>
      <div className="searchBtn">
        <input type="text" placeholder='Search' onChange={filter} />
        <span className='jobDetailsLength'>Total Records {totalRecords}</span> 
        <Link to='/admin'>
          <span className='jobdetailslogout'>Logout</span>
        </Link>
      </div>
      <table style={{ width: 'auto' }}>
        <thead>
          <tr className='listOfHeading'>
            <th className='listOfTitle'>ID</th>
            <th className='listOfTitle'>Designation</th>
            <th className='listOfTitle'>Company</th>
            <th className='listOfTitle'>Experience</th>
            <th className='listOfTitle'>Location</th>
            <th className='listOfTitle'>Vacancy</th>
            <th className='listOfTitle'>PostedOn</th>
            <th className='listOfTitle'>Status</th>
            <th className='listOfTitle'>Name</th>
            <th className='listOfTitle'>Industry</th>
            <th className='listOfTitle'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobFilter.map((job, index) => (
            <tr key={index} className='dashBoardJobDetails'>
              <td>{index + 1}</td>
              <td>{job.designation}</td>
              <td>{job.company}</td>
              <td>{job.experience}</td>
              <td>{job.location}</td>
              <td>{job.vacancy}</td>
              <td>{formatDate(job.postedOn)}</td>
              <td>{job.status}</td>
              <td>{job.name}</td>
              <td>{job.industry}</td>
              <td>
                {editingJobId === job.Job_ID ? (
                  <div className='dashboard-button'>
                   <button className='dash-btn' onClick={() => handleSave(job._id)}>Save</button>
                    <button className='dash-btn' onClick={handleCancel}>Cancel</button>
                  </div>
                ) : (
                  <div className='dashboard-button'>
                   <button className='dash-btn' onClick={() => handleEdit(job._id, job)}>Edit</button>
                    <button className='dash-btn'  onClick={() => handleDelete(job._id)}>Delete</button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingJobId && (
        <div className='edit-form'>
          <h2>Edit Job Details</h2>
          <form>
            <div className='form-group'>
              <label>Designation</label>
              <input
                type='text'
                value={editedFields.designation || ''}
                onChange={(event) => handleChange(event, 'designation')}
              />
            </div>
            <div className='form-group'>
              <label>Company</label>
              <input
                type='text'
                value={editedFields.company || ''}
                onChange={(event) => handleChange(event, 'company')}
              />
            </div>
            <div className='form-group'>
              <label>Experience</label>
              <input
                type='text'
                value={editedFields.experience || ''}
                onChange={(event) => handleChange(event, 'experience')}
              />
            </div>
            <div className='form-group'>
              <label>Location</label>
              <input
                type='text'
                value={editedFields.location || ''}
                onChange={(event) => handleChange(event, 'location')}
              />
            </div>
            <div className='form-group'>
              <label>Vacancy</label>
              <input
                type='text'
                value={editedFields.vacancy || ''}
                onChange={(event) => handleChange(event, 'vacancy')}
              />
            </div>
            <div className='form-group'>
              <label>Posted On</label>
              <input
                type='text'
                value={editedFields.postedOn || ''}
                onChange={(event) => handleChange(event, 'postedOn')}
              />
            </div>
            <div className='form-group'>
              <label>Status</label>
              <input
                type='text'
                value={editedFields.status || ''}
                onChange={(event) => handleChange(event, 'status')}
              />
            </div>
            <div className='form-group'>
              <label>Name</label>
              <input
                type='text'
                value={editedFields.name || ''}
                onChange={(event) => handleChange(event, 'name')}
              />
            </div>
            <div className='form-group'>
              <label>Industry</label>
              <input
                type='text'
                value={editedFields.industry || ''}
                onChange={(event) => handleChange(event, 'industry')}
              />
            </div>
            <div className='form-group'>
        <label>Responsibilities</label>
        <textarea
          value={editedFields.responsibilities || ''}
          onChange={(event) => handleChange(event, 'responsibilities')}
        />
      </div>
      <div className='form-group'>
        <label>Requirements</label>
        <textarea
          value={editedFields.requirements || ''}
          onChange={(event) => handleChange(event, 'requirements')}
        />
      </div>
            <button type='button' className='dashboard-button' onClick={() => handleSave(editingJobId)}>Save</button>
            <button type='button' className='dashboard-button' onClick={handleCancel}>Cancel</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default DashBoardJobPortal;












// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {Link }  from 'react-router-dom'
// import '../../CSS/AdminPage/DashBoardJobPortal.css';

// const DashBoardJobPortal = () => {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [jobFilter, setJobFilter] = useState([])
//   const [editingJobId, setEditingJobId] = useState(null); 
//   const [editedFields, setEditedFields] = useState({});
//   // const [data,setData] = useState({date1:"new"})
//   // 1. get database
//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/data`); // new date() or 
//         setJobDetails(response.data);
//         setJobFilter(response.data)
//       } catch (error) {
//         console.error('Error fetching job details:', error);
//       }
//     };

//     fetchJobDetails();
//   }, []);

// // 2. filter
// const filter = (e) => {
//    setJobFilter(jobDetails.filter(job => job.name.includes(e.target.value)))
//   // console.log(setJob)
  
// };
// const totalRecords = jobFilter.length;

// // 3. cancel
// const handleCancel = () => {
//   setEditingJobId(null);
//   setEditedFields({});
// };

//   //4. delete
//   const handleDelete = async (jobId) => {
//     try {
//       // Send delete request to the backend
//       await axios.delete(`http://localhost:5000/data/${jobId}`);
      
//       // Remove the deleted job from the jobDetails state
//       setJobDetails(prevJobDetails => {
//         return prevJobDetails.filter(job => job.Job_ID !== jobId);
//       });
      
//       console.log('Job deleted successfully');
//     } catch (error) {
//       console.error('Error deleting job:', error);
//     }
//   };

// //   // 5. save database
//   const handleSave = async (jobId) => {
//     try {
//       console.log("Saving job with ID:", jobId);
//       console.log("Updated fields:", editedFields);
//       await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
//       setJobDetails(prevJobDetails => {
//         return prevJobDetails.map(job => {
//           if (job.Job_ID === jobId) {
//             return { ...job, ...editedFields };
//           }
//           return job;
//         });
//       });
//       setEditingJobId(null);
//       console.log('Job updated successfully');
//     } catch (error) {
//       console.error('Error updating job:', error);
//     }
//   };
//   const handleChange = (event, fieldName) => {
//     const newValue = event.target.value;
//     console.log(newValue)
//     setEditedFields(prevState => ({
//       ...prevState,
//       [fieldName]: newValue
//     }));
//   };
//  const handleEdit = (jobId, jobData) => {
//     setEditingJobId(jobId);
//     // Initialize editedFields with current job data
//     setEditedFields(jobData);
//   };


  
  
//   return (
//     <div className='dashboardpage'>
//       <h1 className='dashheading'> Dashboard </h1>
//       <div className="searchBtn">
//         <input type="text"
//         placeholder='search'
//         onChange={filter} />
//         <span className='jobDetailsLength'> Total Records {totalRecords} </span> 
      
//         <Link to='/admin'> 
//         <span className='jobdetailslogout'> Logout</span>
//         </Link>
//       </div>
//       <table style={{ width: 'auto' }}>
//         <thead>
//           <tr className='listOfHeading'>
//             <th className='listOfTitle'>ID</th>
//             <th className='listOfTitle'>Designation</th>
//             <th className='listOfTitle'>Company</th>
//             <th className='listOfTitle'>Experience</th>
//             <th className='listOfTitle'>Location</th>
//             <th className='listOfTitle'>Vacancy</th>
//             <th className='listOfTitle'>PostedOn</th>
//             <th className='listOfTitle'>Status</th>
//             <th className='listOfTitle'>Name</th>
//             <th className='listOfTitle'>Industry</th>
//             <th className='listOfTitle'>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {jobFilter.map((job,index) => (
             
//             <tr key={index} className='dashBoardJobDetails'>
              
//               <td>{index+1}</td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-designation' 
//                     value={editedFields.designation || job.designation}
                    
//                     onChange={(event) => handleChange(event, 'designation')}
//                   />
//                 ) : (
//                   job.designation
//                 )}
//               </td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-company' 
//                     value={editedFields.company|| job.company}
//                     onChange={(event) => handleChange(event, 'company')}
//                   />
//                 ) : (
//                   job.company
//                 )}
//               </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-experience' 
//                     value={editedFields.experience || job.experience}
//                     onChange={(event) => handleChange(event, 'experience')}
//                   />
//                 ) : (
//                   job.experience
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-location' 
//                     value={editedFields.location || job.location}
//                     onChange={(event) => handleChange(event, 'location', job.Job_ID)}
//                   />
//                 ) : (
//                   job.location
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-vacancy' 
//                     value={editedFields.vacancy || job.vacancy}
//                     // onChange={(event) => handleChange(event, 'vacancy', job.Job_ID)}
//                     onChange={(event) => handleChange(event, 'vacancy')}
//                   />
//                 ) : (
//                   job.vacancy
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-posted'
//                     value={editedFields.postedOn || job.postedOn}
//                     onChange={(event) => handleChange(event, 'postedOn')}
//                   />
//                 ) : (
//                   job.postedOn
//                 )}
//                 </td>

//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-status' 
//                     value= {editedFields.status || job.status}
//                     onChange={(event) => handleChange(event, 'status')}
//                   />
//                 ) : (
//                   job.status
//                 )}
//                </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-name' 
//                     value={editedFields.name || job.name}
//                     onChange={(event) => handleChange(event, 'name')}
//                   />
//                 ) : (
//                   job.name
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     className='dashboard-industry' 
//                     value= {editedFields.industry || job.industry}
//                     onChange={(event) => handleChange(event, 'industry')}
//                   />
//                 ) : (
//                   job.industry
//                 )}
//                </td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <div className='dashboard-button' >
//                     <button className='dash-btn'  onClick={() => handleSave(job.Job_ID)}>Save</button>
//                     <button  className='dash-btn' onClick={handleCancel}>Cancel</button>
//                   </div>
//                 ) : (
//                   <div className='dashboard-button'>
//                   <button className='dash-btn' onClick={() => handleEdit(job.Job_ID, job)}>Update</button>
//                   <button className='dash-btn'  onClick={() => handleDelete(job.Job_ID)}>Delete</button>
//                   </div>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default DashBoardJobPortal;


//2.
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import '../../CSS/AdminPage/DashBoardJobPortal.css';

// const DashBoardJobPortal = () => {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [jobFilter, setJobFilter] = useState([]);
//   const [editingJobId, setEditingJobId] = useState(null);
//   const [editedFields, setEditedFields] = useState({});
//   const [showPopup, setShowPopup] = useState(false);
  
//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/data');
//         setJobDetails(response.data);
//         setJobFilter(response.data);
//       } catch (error) {
//         console.error('Error fetching job details:', error);
//       }
//     };

//     fetchJobDetails();
//   }, []);

//   const filter = (e) => {
//     setJobFilter(jobDetails.filter(job => job.name.includes(e.target.value)));
//   };

//   const totalRecords = jobFilter.length;

//   const handleCancel = () => {
//     setEditingJobId(null);
//     setEditedFields({});
//     setShowPopup(false);
//   };

//   const handleDelete = async (jobId) => {
//     try {
//       await axios.delete(`http://localhost:5000/data/${jobId}`);
//       setJobDetails(prevJobDetails => prevJobDetails.filter(job => job.Job_ID !== jobId));
//       console.log('Job deleted successfully');
//     } catch (error) {
//       console.error('Error deleting job:', error);
//     }
//   };

//   //   // 5. save database
//   const handleSave = async (jobId) => {
//     try {
//       console.log("Saving job with ID:", jobId);
//       console.log("Updated fields:", editedFields);
//       await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
//       setJobDetails(prevJobDetails => {
//         return prevJobDetails.map(job => {
//           if (job.Job_ID === jobId) {
//             return { ...job, ...editedFields };
//           }
//           return job;
//         });
//       });
//       setEditingJobId(null);
//       console.log('Job updated successfully');
//     } catch (error) {
//       console.error('Error updating job:', error);
//     }
//   };

//   // const handleSave = async (jobId) => {
//   //   try {
//   //     await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
//   //     setJobDetails(prevJobDetails => {
//   //       return prevJobDetails.map(job => {
//   //         if (job.Job_ID === jobId) {
//   //           return { ...job, ...editedFields };
//   //         }
//   //         return job;
//   //       });
//   //     });
//   //     setEditingJobId(null);
//   //     setShowPopup(false);
//   //     console.log('Job updated successfully');
//   //   } catch (error) {
//   //     console.error('Error updating job:', error);
//   //   }
//   // };

//   const handleChange = (event, fieldName) => {
//     const newValue = event.target.value;
//     setEditedFields(prevState => ({
//       ...prevState,
//       [fieldName]: newValue
//     }));
//   };

//   const handleEdit = (jobId, jobData) => {
//     setEditingJobId(jobId);
//     setEditedFields(jobData);
//     setShowPopup(true);
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${day}-${month}-${year}`;
//   };

//    // Popup component for editing job details
 
//   return (
//     <div className='dashboardpage'>
//       <h1 className='dashheading'>Dashboard</h1>
//       <div className="searchBtn">
//         <input type="text" placeholder='Search' onChange={filter} />
//         <span className='jobDetailsLength'>Total Records {totalRecords}</span> 
//         <Link to='/admin'>
//           <span className='jobdetailslogout'>Logout</span>
//         </Link>
//       </div>
//       <table style={{ width: 'auto' }}>
//         <thead>
//           <tr className='listOfHeading'>
//             <th className='listOfTitle'>ID</th>
//             <th className='listOfTitle'>Designation</th>
//             <th className='listOfTitle'>Company</th>
//             <th className='listOfTitle'>Experience</th>
//             <th className='listOfTitle'>Location</th>
//             <th className='listOfTitle'>Vacancy</th>
//             <th className='listOfTitle'>PostedOn</th>
//             <th className='listOfTitle'>Status</th>
//             <th className='listOfTitle'>Name</th>
//             <th className='listOfTitle'>Industry</th>
//             <th className='listOfTitle'>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {jobFilter.map((job, index) => (
//             <tr key={index} className='dashBoardJobDetails'>
//               <td>{index + 1}</td>
//               <td>{job.designation}</td>
//               <td>{job.company}</td>
//               <td>{job.experience}</td>
//               <td>{job.location}</td>
//               <td>{job.vacancy}</td>
//               <td>{formatDate(job.postedOn)}</td>
//               <td>{job.status}</td>
//               <td>{job.name}</td>
//               <td>{job.industry}</td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <div className='dashboard-button'>
//                     <button className='dash-btn' onClick={() => handleSave(job.Job_ID)}>Save</button>
//                     <button className='dash-btn' onClick={handleCancel}>Cancel</button>
//                   </div>
//                 ) : (
//                   <div className='dashboard-button'>
//                     <button className='dash-btn' onClick={() => handleEdit(job.Job_ID, job)}>Edit</button>
//                     <button className='dash-btn' onClick={() => handleDelete(job.Job_ID)}>Delete</button>
//                   </div>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {editingJobId && (
//         <div className='edit-form'>
//           <h2>Edit Job Details</h2>
//           <form>
//             <div className='form-group'>
//               <label>Designation</label>
//               <input
//                 type='text'
//                 value={editedFields.designation || ''}
//                 onChange={(event) => handleChange(event, 'designation')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Company</label>
//               <input
//                 type='text'
//                 value={editedFields.company || ''}
//                 onChange={(event) => handleChange(event, 'company')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Experience</label>
//               <input
//                 type='text'
//                 value={editedFields.experience || ''}
//                 onChange={(event) => handleChange(event, 'experience')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Location</label>
//               <input
//                 type='text'
//                 value={editedFields.location || ''}
//                 onChange={(event) => handleChange(event, 'location')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Vacancy</label>
//               <input
//                 type='text'
//                 value={editedFields.vacancy || ''}
//                 onChange={(event) => handleChange(event, 'vacancy')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Posted On</label>
//               <input
//                 type='text'
//                 value={editedFields.postedOn || ''}
//                 onChange={(event) => handleChange(event, 'postedOn')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Status</label>
//               <input
//                 type='text'
//                 value={editedFields.status || ''}
//                 onChange={(event) => handleChange(event, 'status')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Name</label>
//               <input
//                 type='text'
//                 value={editedFields.name || ''}
//                 onChange={(event) => handleChange(event, 'name')}
//               />
//             </div>
//             <div className='form-group'>
//               <label>Industry</label>
//               <input
//                 type='text'
//                 value={editedFields.industry || ''}
//                 onChange={(event) => handleChange(event, 'industry')}
//               />
//             </div>
//             <button type='button' onClick={() => handleSave(editingJobId)}>Save</button>
//             <button type='button' onClick={handleCancel}>Cancel</button>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DashBoardJobPortal;





















// const handleSave = async (jobId, updatedJobData) => {
  //   try {
  //     await axios.put(`http://localhost:5000/data/${jobId}`, updatedJobData);
      
  //     // Fetch updated job details after saving
  //     const response = await axios.get(`http://localhost:5000/data`);
  //     setJobDetails(response.data);
  //     setJobFilter(response.data);
      
  //     setEditingJobId(null);
  //     console.log('Job updated successfully');
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //   }
  // };
  
  



  // const handleSave = async (jobId) => {
  //   try {
  //     await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
  //     setEditingJobId(null);
  //     console.log('Job updated successfully');
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //   }
  // };
//   const handleSave = (jobId) => {
//   setJobDetails(prevJobDetails => {
//     return prevJobDetails.map(job => {
//       if (job.Job_ID === jobId) {
//         return { ...job, ...editedFields };
//       }
//       return job;
//     }); 
//   });
//   setEditingJobId(null);   
// };



  // const handleSave = (jobId) => {
  //   // Save changes made to the job
  //    setEditingJobId({editedFields});
    
  //   // You can implement code here to send updates to the backend
  // };

  //6. handlechange
  // const handleChange = (event, fieldName, jobId) => {
  //   const newValue = event.target.value;
  //   setJobDetails(prevJobDetails => {
  //     return prevJobDetails.map(job => {
  //       if (job.Job_ID === jobId) {
  //         return { ...job, [fieldName]: newValue };
          
  //       }
  //       return job;
  //     });
  //   });
  // };


//   // const handleSave = async (jobId) => {
//   //   try {
//   //     // Find the edited job detail
//   //     const editedJob = jobDetails.find(job => job.Job_ID === jobId);
//   //     // Send a PUT request to update the job detail on the server
//   //     const res =await axios.put(`http://localhost:7000/data/${jobId}`, editedJob); // Make sure to use the correct endpoint for updating data
//   //     setEditingJobId(null); // Exit edit mode
//   //     console.log('Job updated successfully');
//   //   } catch (error) {
//   //     console.error('Error updating job:', error);
//   //   }
//   // };
  
//   // const handleSave = async (jobId, newData) => {
//   //   try {
//   //     // Send PUT request to update the job detail on the server
//   //     await axios.put(`http://localhost:5000/data/${jobId}`, newData);
//   //     setEditingJobId(null); // Exit edit mode
//   //     console.log('Job updated successfully');
//   //   } catch (error) {
//   //     console.error('Error updating job:', error);
//   //   }
//   // };

//   // const handleSave = async (jobId, newData) => {
//   //   try {
//   //     const response = await fetch(http://localhost:5000/data/${jobId}, {
//   //       method: 'PUT',
//   //       headers: {
//   //         'Content-Type': 'application/json'
//   //       },
//   //       body: JSON.stringify(newData)
//   //     });
  
//   //     if (!response.ok) {
//   //       throw new Error('Failed to update job');
//   //     }
  
//   // const handleSave = async (jobId, newData) => {
//   //   try {
//   //     // Send PUT request to update the job detail on the server
//   //     await axios.put(`http://localhost:5000/data/${jobId}`, newData);
      
//   //     // Update frontend data with the new data
//   //     setJobDetails(prevJobDetails => {
//   //       return prevJobDetails.map(job => {
//   //         if (job.Job_ID === jobId) {
//   //           return newData; // Replace old data with new data
//   //         }
//   //         return job;
//   //       });
//   //     });

//   //     setEditingJobId(null); // Exit edit mode
//   //     console.log('Job updated successfully');
//   //   } catch (error) {
//   //     console.error('Error updating job:', error);
//   //   }
//   // };
//   // useEffect(() => {
//   //   const fetchJobDetails = async () => {
//   //     try {
//   //       const response = await axios.get(`http://localhost:5000/data?search=${searchQuery}`);
//   //       setJobDetails(response.data);
//   //     } catch (error) {
//   //       console.error('Error fetching job details:', error);
//   //     }
//   //   };

//   //   fetchJobDetails();
//   // }, [searchQuery]); // Add searchQuery as a dependency





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {Link }  from 'react-router-dom'
// import '../../../CSS/Admin/Dashboard/DashBoardJobPortal.css';

// const DashBoardJobPortal = () => {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [jobFilter, setJobFilter] = useState([])
//   const [editingJobId, setEditingJobId] = useState(null); 
//   const [editedFields, setEditedFields] = useState({});
  
//   console.log(editingJobId)

  
//   // 1.
//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/data');
//         setJobDetails(response.data);
//         setJobFilter(response.data)
//       } catch (error) {
//         console.error('Error fetching job details:', error);
//       }
//     };

//     fetchJobDetails();
//   }, []);

// // 2. filter
// const filter = (e) => {
//    setJobFilter(jobDetails.filter(job => job.name.includes(e.target.value)))
//   // console.log(setJob)
  
// };
// const totalRecords = jobFilter.length;

// // 3. cancel
// const handleCancel = () => {
//   setEditingJobId(null);
//   setEditedFields({});
// };

//   //4. delete
//   const handleDelete = async (jobId) => {
//     try {
//       // Send delete request to the backend
//       await axios.delete(`http://localhost:5000/data/${jobId}`);
      
//       // Remove the deleted job from the jobDetails state
//       setJobDetails(prevJobDetails => {
//         return prevJobDetails.filter(job => job.Job_ID !== jobId);
//       });
      
//       console.log('Job deleted successfully');
//     } catch (error) {
//       console.error('Error deleting job:', error);
//     }
//   };

//   const handleSave = async (jobId) => {
//     try {
//       // Send a PUT request to update the job details
//       await axios.put(`http://localhost:5000/data/${jobId}`, editedFields);
      
//       // Update the job details in the local state
//       setJobDetails(prevJobDetails => {
//         return prevJobDetails.map(job => {
//           if (job.Job_ID === jobId) {
//             return { ...job, ...editedFields };
//           }
//           return job;
//         });
//       });
      
//       // Reset editing state
//       setEditingJobId(null);
      
//       console.log('Job updated successfully');
//     } catch (error) {
//       console.error('Error updating job:', error);
//     }
//   };
// //   setJobDetails(prevJobDetails => {
// //     return prevJobDetails.map(job => {
// //       if (job.Job_ID === jobId) {
// //         return { ...job, ...editedFields };
// //       }
// //       return job;
// //     });
// //   });
// //   setEditingJobId(null); 
  
// // };



//   const handleChange = (event, fieldName) => {
//     const newValue = event.target.value;
//     console.log(newValue)
//     setEditedFields(prevState => ({
//       ...prevState,
//       [fieldName]: newValue
//     }));
//   };





//   const handleEdit = (jobId, jobData) => {
//     setEditingJobId(jobId);
//     // Initialize editedFields with current job data
//     setEditedFields(jobData);
//   };




  

//   return (
//     <div className='dashboardpage'>
//       <h1 className='dashheading'> Dashboard </h1>
//       <div className="searchBtn">
//         <input type="text"
//         placeholder='search'
//         onChange={filter} />
//         <span className='jobDetailsLength'> Total Records {totalRecords} </span> 
      
//         <Link to='/admin'> 
//         <span className='jobdetailslogout'> Logout</span>
//         </Link>
//       </div>
//       <table style={{ width: 'auto' }}>
//         <thead>
//           <tr className='listOfHeading'>
//             <th className='listOfTitle'>ID</th>
//             <th className='listOfTitle'>Designation</th>
//             <th className='listOfTitle'>Company</th>
//             <th className='listOfTitle'>Experience</th>
//             <th className='listOfTitle'>Location</th>
//             <th className='listOfTitle'>Vacancy</th>
//             <th className='listOfTitle'>PostedOn</th>
//             <th className='listOfTitle'>Status</th>
//             <th className='listOfTitle'>Name</th>
//             <th className='listOfTitle'>Industry</th>
//             <th className='listOfTitle'>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {jobFilter.map((job,index) => (
          
//             <tr key={index} className='dashBoardJobDetails'>
//               <td>{index+1}</td>
              
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={editedFields.designation || job.designation}
                  
                    
//                     onChange={(event) => handleChange(event, 'designation')}
//                   />
//                 ) : (
//                   job.designation
//                 )}
//               </td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.company}
//                     onChange={(event) => handleChange(event, 'company', job.Job_ID)}
//                   />
//                 ) : (
//                   job.company
//                 )}
//               </td>
              
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.experience}
//                     onChange={(event) => handleChange(event, 'experience', job.Job_ID)}
//                   />
//                 ) : (
//                   job.experience
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.location}
//                     onChange={(event) => handleChange(event, 'location', job.Job_ID)}
//                   />
//                 ) : (
//                   job.location
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.vacancy}
//                     onChange={(event) => handleChange(event, 'vacancy', job.Job_ID)}
//                   />
//                 ) : (
//                   job.vacancy
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.postedon}
//                     onChange={(event) => handleChange(event, 'postedon', job.Job_ID)}
//                   />
//                 ) : (
//                   job.postedon
//                 )}
//                 </td>

//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value= {job.status}
//                     onChange={(event) => handleChange(event, 'status', job.Job_ID)}
//                   />
//                 ) : (
//                   job.status
//                 )}
//                </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value={job.name}
//                     onChange={(event) => handleChange(event, 'name', job.Job_ID)}
//                   />
//                 ) : (
//                   job.name
//                 )}
//                 </td>
//               <td>
//               {editingJobId === job.Job_ID ? (
//                   <input
//                     type="text"
//                     value= {job.industry}
//                     onChange={(event) => handleChange(event, 'industry', job.Job_ID)}
//                   />
//                 ) : (
//                   job.industry
//                 )}
//                </td>
//               <td>
//                 {editingJobId === job.Job_ID ? (
//                   <>
//                     <button onClick={() => handleSave(job.Job_ID)}>Save</button>
//                     <button onClick={handleCancel}>Cancel</button>
//                   </>
//                 ) : (
//                   <>
//                   <button onClick={() => handleEdit(job.Job_ID, job)}>Update</button>
//                   <button onClick={() => handleDelete(job.Job_ID)}>Delete</button>
//                   </>
//                 )}
//               </td>
//             </tr>
            
//           ))
//           }
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default DashBoardJobPortal;


