import React, { useState } from 'react';
import ReactModal from 'react-modal';
import  ITDetails  from '../../Courses/IT/ITDetails'; 
import '../../../CSS/Courses/Courses.css'
import LayOut from '../../Home/NavigationBar/LayOut';
import Button from '@mui/material/Button';
import { IoMdCloseCircle } from "react-icons/io";


const IT = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal=() =>{
    setIsModalOpen(true);
    document.body.classList.add("modal-open")
  }
  const closeModal =() =>{
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedCourse(null)

  }

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    // setIsModalOpen(true);
    openModal();
  };

  // const closeModal = () => {
  //   setSelectedCourse(null); 
  //   setIsModalOpen(false);
  // };

  return (
    <LayOut>
    <div >

      <h2 className='courses-heading'>IT Courses </h2>
      <div className="course-container">
      {ITDetails.map(course => (
        <div key={course.heading}>
          <img src={course.image} alt={course.heading} onClick={() => handleCourseClick(course)} />
          <h3 onClick={() => handleCourseClick(course)}>{course.heading}</h3>
        </div>
      ))}
</div>
      <ReactModal 
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Course Details Modal"
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            transform: "translate(0% ,5%)",
          },
          maxWidth : "80vw", 
          
        }}
      >
        {selectedCourse && (
          <div style={{maxWidth: '75vw', overflow:"auto"}}>
            <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
            <h2 className='popup-Title'>{selectedCourse.heading}</h2>
            <p>{selectedCourse.object}</p>
            <h3>Course Modules</h3>
            <ol>
              {selectedCourse.courseModules.map(module => (
                <li key={module.id}>
                  <h4>{module.title}</h4>
                  <ul>
                    {module.description && module.description.map((desc, index) => (
                      <li key={index}>
                        <p>{desc.subTitle}</p>
                        {desc.Topics && (
                          <ul>
                            {desc.Topics.map((topic, index) => (
                             <li key={index}> {topic.subTopic && topic.subTopic}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
            {/* <p>Fees: {selectedCourse.details[0].fee}</p> */}
            <p>Duration: {selectedCourse.details[0].duration}</p>
            <p>{selectedCourse.enrollNow}</p>
            <div className="courseClose">
            <Button variant="contained" color="primary" onClick={closeModal} > Close </Button>
            </div>
           
          </div>
        )}
      </ReactModal>
    </div>
    </LayOut>
  );
};

export default IT;