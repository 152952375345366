

// import Image1 from '../../Images/HomeSlider/sliderImage1.png';
import Image1 from '../../Images/HomeSlider/sliderImage2.png';
import Image2 from '../../Images/HomeSlider/sliderImage4.png';
import Image3 from '../../Images/HomeSlider/sliderImage5.png';


const SliderImages = [
    {
        url: Image1,
     },
    {
        url: Image2,
    },
    {
        url: Image3,
    }
]
 export default SliderImages;