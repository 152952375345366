

//old code date calculation

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "../../CSS/JobPortal/JobPortal.css";
// import { CiLock, CiLocationOn } from "react-icons/ci";
// import Button from "@mui/material/Button";
// import ReactModal from "react-modal";
// import LayOut from "../Home/NavigationBar/LayOut";
// import { Link } from "react-router-dom";
// import { IoMdCloseCircle } from "react-icons/io";

// function  NonITJobs() {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedJob, setSelectedJob] = useState(null);
//   const [elapsedTimes, setElapsedTimes] = useState({});

//   // Fetch Non-IT job details
//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/data');
//         // Filter for Non-IT jobs
//         const nonItJobs = response.data.filter(job => job.industry !== 'IT');
//         setJobDetails(nonItJobs);
//         console.log('Fetched Non-IT Jobs:', nonItJobs);
//       } catch (error) {
//         console.error('Error fetching Non-IT job details:', error);
//       }
//     };

//     fetchJobDetails();
//   }, []);

//   // Calculate elapsed time for each job
//   useEffect(() => {
//     const calculateElapsedTime = () => {
//       const newElapsedTimes = {};
//       jobDetails.forEach(job => {
//         const postedDate = new Date(job.postedOn);
//         const currentTime = new Date();
//         const timeDifference = currentTime - postedDate;

//         const seconds = Math.floor(timeDifference / 1000);
//         const minutes = Math.floor(seconds / 60);
//         const hours = Math.floor(minutes / 60);
//         const days = Math.floor(hours / 24);

//         const displayDays = days > 0 ? `${days} ${days === 1 ? "day" : "days"} ` : '';
//         const displayHours = (hours % 24) > 0 ? `${hours % 24} ${hours % 24 === 1 ? "hour" : "hours"} ` : '';
//         const displayMinutes = (minutes % 60) > 0 ? `${minutes % 60} ${minutes % 60 === 1 ? "minute" : "minutes"} ` : '';
//         const displaySeconds = (seconds % 60) > 0 ? `${seconds % 60} ${seconds % 60 === 1 ? "second" : "seconds"} ago` : 'just now';

//         newElapsedTimes[job.id] = `${displayDays}${displayHours}${displayMinutes}${displaySeconds}`;
//       });
//       setElapsedTimes(newElapsedTimes);
//     };

//     if (jobDetails.length > 0) {
//       calculateElapsedTime();
//       const intervalId = setInterval(() => {
//         calculateElapsedTime();
//       }, 1000); // Update every second for more accurate time display
//       return () => clearInterval(intervalId); // Clean up interval on unmount
//     }
//   }, [jobDetails]);

//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//     setSelectedJob(null);
//   };

//   const handleJobClick = (job) => {
//     setSelectedJob(job);
//     openModal();
//   };

//   return (
//     <LayOut>
//       <div className="jobPortal">
//         {jobDetails.map((information, index) => (
//           <div key={index} className="searchJob">
//             <div>
//               <div className="designation">{information.designation}</div>
//               <div className="company">{information.company}</div>
//               <div className="work_details">
//                 <span>
//                   <span>
//                     <span className="lock-location-icon"><CiLock /></span> {information.experience}
//                   </span>
//                   <span className="separator1">|</span>
//                   <span>
//                     <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
//                   </span>
//                 </span>
//               </div>
//             </div>
//             <div className="vacancy">
//               <span>
//                 <span>Vacancies: {information.vacancy}</span>
//                 <span className="separator">|</span>
//                 <span>{elapsedTimes[information.id]}</span>
//               </span>
//             </div>
//             <div className="handleJobClick">
//               <Button
//                 variant="contained"
//                 onClick={() => handleJobClick(information)}
//               >
//                 Apply Here
//               </Button>
//             </div>
//           </div>
//         ))}

//         <ReactModal
//           isOpen={isModalOpen}
//           onRequestClose={closeModal}
//           contentLabel="Job Details Modal"
//           shouldCloseOnOverlayClick={false}
//           style={{
//             content: {
//               transform: "translate(0%, 7%)",
//             },
//           }}
//         >
//           {selectedJob && (
//             <div style={{ maxWidth: "90vw", overflow: "auto" }}>
//               <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
//               <div>
//                 <h2>{selectedJob.designation}</h2>
//                 {selectedJob.jobDescription.map((job, index) => (
//                   <div key={index}>
//                     {job.industry && (
//                       <p>
//                         <h3>Industry:</h3>
//                         {job.industry}
//                       </p>
//                     )}

//                     {job.responsibilities && (
//                       <>
//                         <h3>Responsibilities</h3>
//                         <ul>
//                           {job.responsibilities.map((responsibility, idx) => (
//                             <li key={idx}>{responsibility}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.requirements && (
//                       <>
//                         <h3>Requirements</h3>
//                         <ul>
//                           {job.requirements.map((requirement, idx) => (
//                             <li key={idx}>{requirement}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.recommendedSkills && (
//                       <>
//                         <h3>Recommended Skills</h3>
//                         <ul>
//                           {job.recommendedSkills.map((skill, idx) => (
//                             <li key={idx}>{skill}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                   </div>
//                 ))}

//                 <div className="apply-close-btn">
//                   <Link to="/redirect/mail" target="_blank">
//                     <div>
//                       <Button variant="contained">Apply</Button>
//                     </div>
//                   </Link>
//                   <div>
//                     <Button variant="contained" onClick={closeModal}>
//                       Close
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </ReactModal>
//       </div>
//     </LayOut>
//   );
// }

// export default  NonITJobs;



// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "../../CSS/JobPortal/JobPortal.css";
// import { CiLock, CiLocationOn } from "react-icons/ci";
// import Button from "@mui/material/Button";
// import ReactModal from "react-modal";
// import LayOut from "../Home/NavigationBar/LayOut";
// import { Link } from "react-router-dom";
// import { IoMdCloseCircle } from "react-icons/io";

// function  NonITJobs() {
//   const [jobDetails, setJobDetails] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedJob, setSelectedJob] = useState(null);
//   const [elapsedTimes, setElapsedTimes] = useState({});

  // // Fetch Non-IT job details
  // useEffect(() => {
  //   const fetchJobDetails = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:5000/data');
  //       // Filter for Non-IT jobs
  //       const nonItJobs = response.data.filter(job => job.industry !== 'IT');
  //       setJobDetails(nonItJobs);
  //       console.log('Fetched Non-IT Jobs:', nonItJobs);
  //     } catch (error) {
  //       console.error('Error fetching Non-IT job details:', error);
  //     }
  //   };

//     fetchJobDetails();
//   }, []);

//   const calculateElapsedTime = (dateString) => {
//     const postedDate = new Date(dateString);
//     const currentDate = new Date();
//     const elapsedMilliseconds = currentDate - postedDate;
//     const elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
//     return elapsedDays > 0 ? `${elapsedDays} days ago` : 'Posted today';
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//     setSelectedJob(null);
//   };

//   const handleJobClick = (job) => {
//     setSelectedJob(job);
//     openModal();
//   };

//   return (
//     <LayOut>
//       <div className="jobPortal">
//         {jobDetails.map((information, index) => (
//           <div key={index} className="searchJob">
//             <div>
//               <div className="designation">{information.designation}</div>
//               <div className="company">{information.company}</div>
//               <div className="work_details">
//                 <span>
//                   <span>
//                     <span className="lock-location-icon"><CiLock /></span> {information.experience}
//                   </span>
//                   <span className="separator1">|</span>
//                   <span>
//                     <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
//                   </span>
//                 </span>
//               </div>
//             </div>
//             <div className="vacancy">
//               <span>
//                 <span>Vacancies: {information.vacancy}</span>
//                 <span className="separator">|</span>
//                 <span> ({calculateElapsedTime(information.postedOn)})</span>
//               </span>
//             </div>
//             <div className="handleJobClick">
//               <Button
//                 variant="contained"
//                 onClick={() => handleJobClick(information)}
//               >
//                 Apply Here
//               </Button>
//             </div>
//           </div>
//         ))}

//         <ReactModal
//           isOpen={isModalOpen}
//           onRequestClose={closeModal}
//           contentLabel="Job Details Modal"
//           shouldCloseOnOverlayClick={false}
//           style={{
//             content: {
//               transform: "translate(0%, 7%)",
//             },
//           }}
//         >
//           {selectedJob && (
//             <div style={{ maxWidth: "90vw", overflow: "auto" }}>
//               <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
//               <div>
//                 <h2>{selectedJob.designation}</h2>
//                 {selectedJob.jobDescription.map((job, index) => (
//                   <div key={index}>
//                     {job.industry && (
//                       <p>
//                         <h3>Industry:</h3>
//                         {job.industry}
//                       </p>
//                     )}

//                     {job.responsibilities && (
//                       <>
//                         <h3>Responsibilities</h3>
//                         <ul>
//                           {job.responsibilities.map((responsibility, idx) => (
//                             <li key={idx}>{responsibility}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.requirements && (
//                       <>
//                         <h3>Requirements</h3>
//                         <ul>
//                           {job.requirements.map((requirement, idx) => (
//                             <li key={idx}>{requirement}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.recommendedSkills && (
//                       <>
//                         <h3>Recommended Skills</h3>
//                         <ul>
//                           {job.recommendedSkills.map((skill, idx) => (
//                             <li key={idx}>{skill}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                   </div>
//                 ))}

//                 <div className="apply-close-btn">
//                   <Link to="/redirect/mail" target="_blank">
//                     <div>
//                       <Button variant="contained">Apply</Button>
//                     </div>
//                   </Link>
//                   <div>
//                     <Button variant="contained" onClick={closeModal}>
//                       Close
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </ReactModal>
//       </div>
//     </LayOut>
//   );
// }

// export default  NonITJobs;




import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../CSS/JobPortal/JobPortal.css";
import { CiLock, CiLocationOn } from "react-icons/ci";
import Button from "@mui/material/Button";
import ReactModal from "react-modal";
import LayOut from "../Home/NavigationBar/LayOut";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";

function NonITJobs() {
  const [jobDetails, setJobDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  // Fetch Non-IT job details
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get('http://localhost:5000/data');
        // Filter for Non-IT jobs
        const nonItJobs = response.data.filter(job => job.industry !== 'IT');
        setJobDetails(nonItJobs);
        console.log('Fetched Non-IT Jobs:', nonItJobs);
      } catch (error) {
        console.error('Error fetching Non-IT job details:', error);
      }
    };
    fetchJobDetails();
    
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedJob(null);
  };

  const handleJobClick = async (job) => {
    try {
      const response = await axios.get(`http://localhost:5000/job/${job._id}`);
      setSelectedJob(response.data);
      openModal();
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const calculateElapsedTime = (dateString) => {
    const postedDate = new Date(dateString);
    const currentDate = new Date();
    const elapsedMilliseconds = currentDate - postedDate;
    const elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
    return elapsedDays > 0 ? `${elapsedDays} days ago` : 'Posted today';
  };

  return (
    <LayOut>
      <div className="jobPortal">
        {jobDetails.map((information) => (
          <div key={information._id} className="searchJob">
            <div>
              <div className="designation">{information.designation}</div>
              <div className="company">{information.company}</div>
              <div className="work_details">
                <span>
                  <span className="lock-location-icon"><CiLock /></span> {information.experience}
                  <span className="separator1">|</span>
                  <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
                </span>
              </div>
            </div>
            <div className="vacancy">
              <span>
                <span>Vacancies: {information.vacancy}</span>
                <span className="separator">|</span>
                <span> ({calculateElapsedTime(information.postedOn)})</span>
              </span>
            </div>
            <div className="handleJobClick">
              <Button
                variant="contained"
                onClick={() => handleJobClick(information)}
              >
                Apply Here
              </Button>
            </div>
          </div>
        ))}

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Job Details Modal"
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              transform: "translate(0%, 7%)",
            },
          }}
        >
          {selectedJob && (
            <div style={{ maxWidth: "90vw", overflow: "auto" }}>
              <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
              <div>
                <h2>{selectedJob.designation}</h2>
                {selectedJob.industry && (
                  <p>
                    <h3>Industry:</h3>
                    {selectedJob.industry}
                  </p>
                )}
                {selectedJob.responsibilities && (
                  <>
                    <h3>Responsibilities</h3>
                    <ul>
                      {selectedJob.responsibilities.map((responsibility, idx) => (
                        <li key={idx}>{responsibility}</li>
                      ))}
                    </ul>
                  </>
                )}
                {selectedJob.requirements && (
                  <>
                    <h3>Requirements</h3>
                    <ul>
                      {selectedJob.requirements.map((requirement, idx) => (
                        <li key={idx}>{requirement}</li>
                      ))}
                    </ul>
                  </>
                )}
                <div className="apply-close-btn">
                  <Link to="/redirect/mail" target="_blank">
                    <div>
                      <Button variant="contained">Apply</Button>
                    </div>
                  </Link>
                  <div>
                    <Button variant="contained" onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    </LayOut>
  );
}

export default NonITJobs;















