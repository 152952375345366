
// signIn page

import React, { useState } from "react";
import axios from 'axios';
import '../../CSS/AdminPage/AdminSignIn.css';
import { Link, useNavigate } from 'react-router-dom';

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    // const history = useHistory();

    const navigate = useNavigate()
 // New state to track login status

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await verifyAndLoginUser(email, password);
            if (result === 'success') {
                console.log("Login successful");
                sessionStorage.setItem('isLoggedIn', true);
                // navigate('/fileupload')
                navigate('/admin-menubar')
                //  history.push('/fileupload');
                setLoggedIn(true); // Set login status to true
            } else {
                setErrorMessage("Invalid email or password");
            }
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Something went wrong");
        }
    };

    const verifyAndLoginUser = async (email, password) => {
        try {
            const response = await axios.post('http://localhost:3001/login', { email, password });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="signInPage" id="loginform">
            <form onSubmit={handleSubmit} action="" className="admin-signin">
                <h3 className="signHeading">Sign In</h3>
                <div className="signInPut">
                    <label htmlFor="email"> Email </label>
                    <br />
                    <input
                        type="email"
                        placeholder="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="signInPut">
                    <label htmlFor="password"> Password </label> <br />
                    <input
                        type="password"
                        placeholder="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {errorMessage && <div className="error">{errorMessage}</div>}
                <div className="remember-forgot">
                    <label htmlFor="">
                        <input type="checkbox" required />
                        Remember me
                    </label>
                    <a href="#"> Forgot Password?</a>
                </div>
                 {loggedIn ? ( // Render Link only if loggedIn is true */}
                    <Link to='/fileupload'>
                        <button className="signInButton" type="submit">Submit</button>
                    </Link>
                ) : ( 
                    <button  className="signInButton" type="submit">Submit</button>
                )}
                <br />
            </form>
        </div>
    );
}

export default SignIn;








// import React, { useState } from "react";
// import '../../CSS/AdminPage/SignInCss.css';
// import {Link} from 'react-router-dom';

// function SignIn() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     localStorage.setItem("email", email);
//     localStorage.setItem("password", password);
//     setEmail("");
//     setPassword("");
//   };

//   return (
//     <div className="signInPage" id="loginform">
//       <form onSubmit={handleSubmit} action="">
//         <h3 className="signHeading">Sign In</h3>
//         <div className="signInPut">
//           <label htmlFor="email"> Email </label>
//           <br />
//           <input
//             type="email"
//             placeholder="email"
//             id="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="signInPut">
//           <label htmlFor="password"> Password </label> <br />
//           <input
//             type="password"
//             placeholder="password"
//             id="password"
//             name="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <div className="remember-forgot">
//           <label htmlFor="">
//           <input type="checkbox" required />
//             Remember me
//           </label>
//           <a href="#"> Forgot Password?</a>
//         </div>
//         <Link to='/fileupload'> 
//         <button className="signInButton" type="sumbit" >Sumbit</button>
//         </Link>
//         <br />
//       </form>
//     </div>
//   );
// }

// export default SignIn;
// import React, { useState } from "react";
// import '../../CSS/AdminPage/SignInCss.css';
// import { Link } from 'react-router-dom';

// function SignIn() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (validateEmail(email) && validatePassword(password)) {
//       localStorage.setItem("email", email);
//       localStorage.setItem("password", password);
//       setEmail("");
//       setPassword("");
//       setEmailError("");
//       setPasswordError("");
//     }
//   };

//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!regex.test(email)) {
//       setEmailError("Invalid email address");
//       return false;
//     }
//     setEmailError("");
//     return true;
//   };

//   const validatePassword = (password) => {
//     if (password.length < 6) {
//       setPasswordError("Password must be at least 6 characters long");
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   return (
//     <div className="signInPage" id="loginform">
//       <form onSubmit={handleSubmit} action="">
//         <h3 className="signHeading">Sign In</h3>
//         <div className="signInPut">
//           <label htmlFor="email"> Email </label>
//           <br />
//           <input
//             type="email"
//             placeholder="email"
//             id="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           {emailError && <span className="error">{emailError}</span>}
//         </div>
//         <div className="signInPut">
//           <label htmlFor="password"> Password </label> <br />
//           <input
//             type="password"
//             placeholder="password"
//             id="password"
//             name="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           {passwordError && <span className="error">{passwordError}</span>}
//         </div>
//         <div className="remember-forgot">
//           <label htmlFor="">
//             <input type="checkbox" required />
//             Remember me
//           </label>
//           <a href="#"> Forgot Password?</a>
//         </div>
//         {/* <Link to='/fileupload'>  */}
//           <button className="signInButton" type="submit">Submit</button>
//         {/* </Link> */}
       
//       </form>
//     </div>
//   );
// }

// export default SignIn;

// import React, { useState } from "react";
// import { withRouter } from "react-router";
// // import { withRouter } from "react-router-dom"; 
// import '../../CSS/AdminPage/SignInCss.css';
// import { Link } from 'react-router-dom';

// function SignIn({ history }) { // Destructure history from props
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateEmail(email) && validatePassword(password)) {
//       localStorage.setItem("email", email);
//       localStorage.setItem("password", password);
//       setEmail("");
//       setPassword("");
//       setEmailError("");
//       setPasswordError("");
//       history.push("/fileupload"); // Navigate to AdminCsvFileUpload component
//     }
//   };

//    const validateEmail = (email) => {
//      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//      if (!regex.test(email)) {
//        setEmailError("Invalid email address");
//        return false;
//      }
//      setEmailError("");
//     return true;
//    };

//    const validatePassword = (password) => {
//     if (password.length < 6) {
//       setPasswordError("Password must be at least 6 characters long");
//        return false;
//     }
//      setPasswordError("");
//      return true;
//   };


//   return (
//     <div className="signInPage" id="loginform">
//       <form onSubmit={handleSubmit} action="">
//         <h3 className="signHeading">Sign In</h3>
//         <div className="signInPut">
//           <label htmlFor="email"> Email </label>
//           <br />
//           <input
//             type="email"
//             placeholder="email"
//             id="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           {emailError && <span className="error">{emailError}</span>}
//         </div>
//         <div className="signInPut">
//           <label htmlFor="password"> Password </label> <br />
//           <input
//             type="password"
//             placeholder="password"
//             id="password"
//             name="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           {passwordError && <span className="error">{passwordError}</span>}
//         </div>
//         <div className="remember-forgot">
//           <label htmlFor="">
//             <input type="checkbox" required />
//             Remember me
//           </label>
//           <a href="#"> Forgot Password?</a>
//         </div>
//         <Link to='/fileupload'> 
//           <button className="signInButton" type="submit">Submit</button>
//         </Link>
//         <br />
//       </form>
//     </div>
//   );
// }

// export default withRouter(SignIn); // Wrap component with withRouter

// import React, { useState } from "react";
// import '../../CSS/AdminPage/SignInCss.css';
// import { Link } from 'react-router-dom'; 
// // import useHistory from 'react-router-dom';

// function SignIn() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");
  
  

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateEmail(email) && validatePassword(password)) {
//       localStorage.setItem("email", email);
//       localStorage.setItem("password", password);
//       setEmail("");
//       setPassword("");
//       setEmailError("");
//       setPasswordError("");
       
//     }
//   };

  
//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!regex.test(email)) {
//       setEmailError("Invalid email address");
//       return false;
//     }
//     setEmailError("User Invalid");
//    return true;
//   };

//   const validatePassword = (password) => {
//    if (password.length < 6) {
//      setPasswordError("Password must be at least 6 characters long");
//       return false;
//    }
//     setPasswordError(" User Invalid");
//     return true;
//  };

//   return (
//     <div className="signInPage" id="loginform">
//       <form onSubmit={handleSubmit} action="">
//         <h3 className="signHeading">Sign In</h3>
//         <div className="signInPut">
//           <label htmlFor="email"> Email </label>
//           <br />
//           <input
//             type="email"
//             placeholder="email"
//             id="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           {emailError && <span className="error">{emailError}</span>}
//         </div>
//         <div className="signInPut">
//           <label htmlFor="password"> Password </label> <br />
//           <input
//             type="password"
//             placeholder="password"
//             id="password"
//             name="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           {passwordError && <span className="error">{passwordError}</span>}
//         </div>
//         <div className="remember-forgot">
//           <label htmlFor="">
//             <input type="checkbox" required />
//             Remember me
//           </label>
//           <a href="#"> Forgot Password?</a>
//         </div>
//         <Link to='/fileupload'> 
//           <button className="signInButton" type="submit">Submit</button>
//         </Link>
//         <br />
//       </form>
//     </div>
//   );
// }

// export default SignIn;


