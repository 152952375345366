import React, { useState } from 'react';
import { NonITDetails } from '../Non-IT/Non-ITDetails';
import ReactModal from 'react-modal';
import LayOut from '../../Home/NavigationBar/LayOut';
import Button from '@mui/material/Button';
import '../../../CSS/Courses/Courses.css';
import { IoMdCloseCircle } from "react-icons/io";


const NonITCoursess = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <LayOut> 
    <div>
      <h2 className='courses-heading'>Non-IT Courses</h2>
      <div className="course-container"> 
      {NonITDetails.map(course => (
        <div key={course.heading}>
          <img src={course.image} alt={course.heading} onClick={() => handleCourseClick(course)}/>
          <h3 onClick={() => handleCourseClick(course)}>{course.heading}</h3>
        </div>
      ))}
      </div>

      <ReactModal 
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Course Details Modal"
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            transform: "translate(0% ,5%)",
          },
          maxWidth : "80vw", 
          
        }}
      >
        {selectedCourse && (
          <div style={{maxWidth: '75vw', overflow:"auto", }}>
            <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
            <h2 className='popup-Title'>{selectedCourse.heading}</h2>
            <p>{selectedCourse.object}</p>
            <h3>Course Modules:</h3>
            <ol>
              {selectedCourse.courseModules.map(module => (
                <li key={module.id}>
                  <h4>{module.title}</h4>
                  <p>{module.objective && module.objective}</p>
                  <ul>
                    {module.description.map((desc, index) => (
                      <li key={index}>
                        <p>{desc.subTitle}</p>
                        
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
            {/* <p>Fees: {selectedCourse.details.fees}</p> */}
            <p>Duration: {selectedCourse.details.duration}</p>
            <p>Enroll Now : {selectedCourse.enrollNow}</p>
            <div className="courseClose">
            <Button variant="contained" color="primary" onClick={closeModal} > Close </Button>
            </div>
            {/* <Button variant="contained" color="primary" onClick={closeModal} > Close </Button> */}
          </div>
        )}
      </ReactModal>
    </div>
    </LayOut>
  );
};

export default NonITCoursess;

