import React from 'react'
import { Link } from 'react-router-dom';


const Courses = () => {
    return (

      <div className="dropdown-menu"> 
              <ul className='dropdown-list'>
                <li className='courses-topics'><Link to="/courses/it">IT</Link></li>
                <li className='courses-topics'><Link to="/courses/non-it">Non-IT</Link></li>
                <li className='courses-topics'><Link to="/courses/core">Core</Link></li>
              </ul>
              </div>
    );
  };
export default Courses

