
import React, { useState } from 'react';
import { MdLibraryAdd, MdExitToApp, MdList } from 'react-icons/md';
import { FaFileArrowUp } from "react-icons/fa6";
import { FaHome, FaBars, FaUserPlus, FaUserCircle, FaFileCsv } from 'react-icons/fa';
import '../../CSS/AdminPage/AdminMenuBar.css';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Images/Logo/My Inception Logo.png'
import AdminCsvFileUpload from './AdminCsvFileUpload';
import DashBoardJobPortal from './DashBoardJobPortal';



function AdminMenuBar() {
  const [menuActive, setMenuActive] = useState(false);
  const [showAccessPermission, setShowAccessPermission] = useState(false);
  const [showAssignSkillTest, setShowAssignSkillTest] = useState(false);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
 
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };




  const navigateToHome = () => {
    navigate('/');
    setMenuActive(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
    setMenuActive(false);
  };

  const handleAccessPermissionClick = () => {
    setShowAccessPermission(!showAccessPermission);
    setShowAssignSkillTest(false); 
    setShowProfileSettings(false);
    setMenuActive(false);
  };

  const handleAssignSkillTestClick = () => {
    setShowAssignSkillTest(!showAssignSkillTest);
    setShowAccessPermission(false);
    setMenuActive(false);
  };






  return (
    <div className="admin-menubar-container">
      <div className={`admin-menubar ${menuActive ? 'active' : ''}`}>
        <div className="admin-menubar-logo-container">
        <img src={Logo} alt="Logo" className="admin-menubar-logo" />
        </div>
        <div className="admin-menubar-header">
          <div className="admin-menubar-item" onClick={handleAccessPermissionClick}>
            <MdLibraryAdd />
            <span>Csv Upload</span>
          </div>
          <div className="admin-menubar-item" onClick={handleAssignSkillTestClick}>
            <FaUserPlus />
            <span>Dashboard</span>
          </div>
          
         
      
         
        </div>
        <div className="admin-menubar-footer">
          <div className="admin-menubar-item" onClick={navigateToHome}>
            <FaHome />
            <span>Home</span>
          </div>
          <div className="admin-menubar-item" onClick={handleLogout}>
            <MdExitToApp />
            <span>Logout</span>
          </div>
        </div>
      </div>
      <div className="admin-menubar-main-content">
        <div className="admin-menubar-hamburger-menu" onClick={toggleMenu}>
          <FaBars />
        </div>
        {showAccessPermission && <AdminCsvFileUpload/>}
        {showAssignSkillTest && <DashBoardJobPortal />}
      
        {/* {showProfileSettings && <AdminProfileSettings userId={sessionStorage.getItem('admin-userId')} />}
        <div className="admin-profile-settings-icon-container" onClick={toggleProfileSettings}>
          <FaUserCircle className="admin-profile-icon" />
        </div> */}
      </div>
    </div>
  );
}

export default AdminMenuBar;
